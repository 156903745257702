import React, {useEffect, useRef, useState} from "react";
import {
    App,
    Button,
    Card,
    Col,
    DatePicker,
    Divider,
    Drawer,
    Form,
    Row,
    Select,
    Space,
    Statistic,
    Table,
    Tag
} from 'antd';
import {
    DownCircleOutlined,
    FieldTimeOutlined,
    FilterOutlined,
    FireOutlined,
    UpCircleOutlined,
    FireTwoTone,
    PlusOutlined,
    InfoCircleOutlined
} from "@ant-design/icons";
import dayjs from "dayjs";
import CountUp from 'react-countup';
import {getColumnSearchProps} from "../../../Helper/Helper";

const {RangePicker} = DatePicker;

const DescriptionItem = ({ title, content }) => (
    <div className="site-description-item-profile-wrapper">
        <p className="site-description-item-profile-p-label">{title}:</p>
        {content}
    </div>
);

const labelsCategory = {
    shared: "Paylaşımlı",
    deposit: "Yatırım",
    loss: "Kayıp",
};
const labelsOperation = {
    "new": "Talep",
    "order": "Beklemede",
    "on_process": "İşlemde",
    "approved": "Onaylandı",
    "rejected": "Red"
};

const colorOperation = {
    "new": "blue",
    "order": "black",
    "on_process": "purple",
    "approved": "green",
    "rejected": "red"
};

const ReportAdmin = () => {

    const {message, notification, modal} = App.useApp();

    const [getDrawer, setDrawer] = useState(false);
    const [getDrawerObject, setDrawerObject] = useState(null);
    const [getFilterView, setFilterview] = useState(true);
    const [getLiveInterval, setLiveInterval] = useState(false);
    const [getLoading, setLoading] = useState(true);
    const [getDataAnalytics, setDataAnalytics] = useState([]);
    const [getFilter, setFilter] = useState(null);
    const liveInterval = useRef(null);
    const [formFilter] = Form.useForm();

    useEffect(() => {
        formFilter.setFieldsValue({
            date: [dayjs().startOf('day'), dayjs().endOf('day')]
        })

        formFilter.submit();

        setFilterview(false);
    }, []);


    const startInterval = () => {
        if(getFilter !== null) {
            if (!getLiveInterval) {
                liveInterval.current = setInterval(() => {
                    getReportVies(getFilter)

                }, 2500);
                setLiveInterval(true);
            }
        }else{
            message.error("Filtre Seçiniz");
        }

    };

    const stopInterval = () => {
        clearInterval(liveInterval.current);
        setLiveInterval(false);
    };


    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            width: 100,
            ...getColumnSearchProps("id")
        },
        {
            title: 'Kullanıcı',
            dataIndex: 'username',
            key: 'username',
            width: 100,
            ...getColumnSearchProps("username")
        },
        {
            title: 'ID',
            dataIndex: 'bonus_rule',
            key: 'bonus_rule',
            render: (bonus_rule, record) => bonus_rule.title
        },
        {
            title: 'Açıklama',
            dataIndex: 'temp_bonus',
            key: 'temp_bonus',
            render: (temp_bonus, record) => {

                if(record.operation !== "new"){

                    return temp_bonus.response[temp_bonus.option.split("/")[0]].data[temp_bonus.option.split("/")[1]].description;
                }else{
                    return <Tag color={"red"}>Beklemede</Tag>
                }

            }
        },
        {
            title: 'Açıklama',
            dataIndex: 'temp_bonus',
            key: 'temp_bonus',
            render: (temp_bonus, record) => {

                if(record.operation !== "new"){
                    const SeparateOrder = temp_bonus.option.split("/");
                    return  new Intl
                        .NumberFormat('tr-TR', { style: 'currency', currency: 'TRY' })
                        .format(
                            temp_bonus.response[SeparateOrder[0]].data[SeparateOrder[1]].calculate
                        );
                }else{
                    return <Tag color={"red"}>Beklemede</Tag>
                }

            }
        },
        {
            title: 'Durum',
            dataIndex: 'operation',
            key: 'operation',
            render: (operation, record) => {

                const labelsOperation = {
                    "new": "Talep",
                    "order": "Beklemede",
                    "on_process": "İşlemde",
                    "approved": "Onaylandı",
                    "rejected": "Red"
                };

                const colorOperation = {
                    "new": "blue",
                    "order": "black",
                    "on_process": "purple",
                    "approved": "green",
                    "rejected": "red"
                };

                return <Tag color={colorOperation[operation]}>{labelsOperation[operation]}</Tag>

            }
        },
        {
            title: 'Durum',
            dataIndex: 'operation',
            key: 'operation',
            render: (operation, record) => {

                const labelsOperation = {
                    "new": "Talep",
                    "order": "Beklemede",
                    "on_process": "İşlemde",
                    "approved": "Onaylandı",
                    "rejected": "Red"
                };

                const colorOperation = {
                    "new": "blue",
                    "order": "black",
                    "on_process": "purple",
                    "approved": "green",
                    "rejected": "red"
                };

                return <Tag color={colorOperation[operation]}>{labelsOperation[operation]}</Tag>

            }
        },
        {
            title: 'Kayıt',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (created_at, record) => {

                return dayjs(created_at).format('DD/MM/YYYY HH:mm:ss');

            }
        },
        {
            title: 'İşlemler',
            dataIndex: 'id',
            key: 'id',
            render: (status, record) =>
                <Button
                    size={"small"}
                    onClick={() => {
                        setDrawerObject(record);
                        setDrawer(true);

                    }}
                    type={"default"}
                    shape={"round"}
                    icon={<InfoCircleOutlined/>}
                >İncele</Button>
        }
    ];




    const getReportVies  = (values) => {

        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);
        myHeaders.append("Accept", "application/json");

        const formdata = new FormData();
        formdata.append("start_date", dayjs(values.date[0]).format("DD/MM/YYYY HH:mm:ss"));
        formdata.append("end_date", dayjs(values.date[1]).format("DD/MM/YYYY HH:mm:ss"));
        formdata.append("operation", values.operation);
        formdata.append("category", values.category);

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formdata,
            redirect: "follow"
        };

        setLoading(true);
        fetch("https://otobonus.thecoder365.com/api/admin/bonus/rule/report/list", requestOptions)
            .then((response) => response.json())
            .then((result) => {

                if(result.status !== "success"){
                    throw new Error(result.message);
                }

                setDataAnalytics(result.data);
                setLoading(false);

            })
            .catch((error) => {
                setLoading(false);
                message.error(error.message);
            });

    }

    const onFilter = (values) => {

        setFilter(values);

        getReportVies(values);

    }

    return (
        <>


            <Card
                styles={{body: {padding: getFilterView ? "20px 20px" : "0px 20px"}}}
                size={"small"}
                bordered={true}
                title="Filtreleme"
                extra={<Space>


                    <Button
                        size={"small"}
                        onClick={() => {
                            if (!getLiveInterval) {
                                startInterval();
                            } else {
                                stopInterval();
                            }
                        }}
                        type={"primary"}
                        shape={"round"}
                        icon={getLiveInterval ? <FireTwoTone/> : <FireOutlined/>}>
                        Canlı
                    </Button>

                    <Button
                        size={"small"}
                        onClick={() => {
                            setFilterview(!getFilterView);
                        }}
                        type={"link"}
                        shape={"round"}
                        icon={!getFilterView ? <DownCircleOutlined style={{fontSize: 18}}/> :
                            <UpCircleOutlined style={{fontSize: 18}}/>}/>


                </Space>}>


                {
                    getFilterView &&
                    <Form
                        form={formFilter}
                        name="formFilter"
                        layout={"vertical"}
                        onFinish={onFilter}
                        autoComplete="off"
                        initialValues={{
                            operation: "all",
                            category: "all",
                        }}
                    >

                        <Row gutter={[16, 16]}>
                            <Col span={6}>
                                <Form.Item
                                    label="Tarih aralıgı"
                                    name="date"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Tarih Seçiniz!',
                                        },
                                    ]}
                                >
                                    <RangePicker
                                        renderExtraFooter={() => {
                                            return (
                                                <>
                                                    <Row style={{marginTop: 10, marginBottom: 10}} gutter={[16, 16]}>
                                                        <Col span={8}>
                                                            <Button block icon={<FieldTimeOutlined/>} onClick={() => {
                                                                formFilter.setFieldsValue({
                                                                    date: [dayjs().startOf('day'), dayjs().endOf('day')]
                                                                })
                                                            }} type="dashed">
                                                                Bugün
                                                            </Button>
                                                        </Col>
                                                        <Col span={8}>
                                                            <Button block icon={<FieldTimeOutlined/>} onClick={() => {
                                                                formFilter.setFieldsValue({
                                                                    date: [dayjs().subtract(1, 'day').startOf('day'), dayjs().subtract(1, 'day').endOf('day')]
                                                                })
                                                            }} type="dashed">
                                                                Önceki Gün
                                                            </Button>
                                                        </Col>
                                                        <Col span={8}>
                                                            <Button block icon={<FieldTimeOutlined/>} onClick={() => {
                                                                formFilter.setFieldsValue({
                                                                    date: [dayjs().startOf('day'), dayjs().subtract(5, "days").endOf('day')]
                                                                })
                                                            }} type="dashed">
                                                                Son 7 Gün
                                                            </Button>
                                                        </Col>

                                                    </Row>
                                                </>
                                            )
                                        }}
                                        showTime/>
                                </Form.Item>
                            </Col>
                            <Col span={6}>

                                <Form.Item
                                    label="Kategori"
                                    name="category"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Kategori Seçiniz!',
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        placeholder="Seçim Yapınız"
                                        filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        options={[
                                            {value: 'all', label: 'Hepsi'},
                                            {value: 'deposit', label: 'Yatırım'},
                                            {value: 'loss', label: 'Kayıp'},
                                            {value: 'shared', label: 'Shared'},
                                        ]}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={6}>

                                <Form.Item
                                    label="Durum"
                                    name="operation"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Durum Seçiniz!',
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        placeholder="Seçim Yapınız"
                                        filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        options={[
                                            {value: 'all', label: 'Hepsi'},
                                            {value: 'new', label: 'Talep'},
                                            {value: 'order', label: 'Beklemede'},
                                            {value: 'on_process', label: 'İşlemde'},
                                            {value: 'approved', label: 'Onaylandı'},
                                            {value: 'rejected', label: 'Red Edildi'},
                                        ]}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label=" ">
                                    <Button
                                        size={"small"}
                                        onClick={() => {
                                            formFilter.submit();
                                        }}
                                        type={"primary"}
                                        shape={"round"}
                                        icon={<FilterOutlined/>}>Filtrele</Button>
                                </Form.Item>
                            </Col>
                        </Row>

                    </Form>
                }
            </Card>



            <Card
                style={{marginTop:20}}
                size={"small"}
                bordered={true}
                title="Yatırım kuralları">
                <Table size={"small"} dataSource={getDataAnalytics} columns={columns}/>
            </Card>


            <Drawer width={600} title={`${getDrawerObject?.id ?? "X"} Numaralı talep`} onClose={() => {
                setDrawerObject(null);
                setDrawer(false)
            }}
                    open={getDrawer}>

                <p
                    className="site-description-item-profile-p"
                    style={{
                        marginBottom: 24,
                    }}>
                    Talep İçeriği
                </p>
                <Divider/>
                <Row>
                    <Col span={12}>
                        <DescriptionItem title="Kullanıcı Adı" content={getDrawerObject?.username}/>
                    </Col>
                </Row>
                <Divider/>

                <Row>
                    <Col span={12}>
                        <DescriptionItem title="ID" content={getDrawerObject?.id}/>
                    </Col>
                    <Col span={12}>
                        <DescriptionItem title="Kural ID" content={getDrawerObject?.bonus_rule?.id}/>
                    </Col>
                    <Col span={12}>
                        <DescriptionItem title="BetCode ID" content={getDrawerObject?.bonus_id}/>
                    </Col>
                </Row>
                <Divider/>

                <Row>
                    <Col span={12}>
                        <DescriptionItem title="Kategori" content={labelsCategory[getDrawerObject?.category]}/>
                    </Col>

                    <Col span={12}>
                        <DescriptionItem title="Durum" content={<Tag color={colorOperation[getDrawerObject?.operation]}>{labelsOperation[getDrawerObject?.operation]}</Tag>}/>
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <DescriptionItem title="Bonus" content={`${getDrawerObject?.temp_bonus.response[getDrawerObject?.temp_bonus.option.split("/")[0]].data[getDrawerObject.temp_bonus.option.split("/")[1]].title}`}/>
                    </Col>
                    <Col span={12}>
                        <DescriptionItem title="Tutar" content={`${getDrawerObject?.temp_bonus.response[getDrawerObject?.temp_bonus.option.split("/")[0]].data[getDrawerObject.temp_bonus.option.split("/")[1]].calculate} ₺`}/>
                    </Col>
                    <Col span={24}>
                        <DescriptionItem title="Bonus açıklaması" content={""}/>
                        {getDrawerObject?.temp_bonus.response[getDrawerObject?.temp_bonus.option.split("/")[0]].data[getDrawerObject.temp_bonus.option.split("/")[1]].description}
                    </Col>
                </Row>

                <Divider/>
                <Row>
                    <Col span={12}>
                        <DescriptionItem title="İşlem Kayıt" content={dayjs(getDrawerObject?.created_at).format('DD/MM/YYYY HH:mm:ss')}/>
                    </Col>
                    <Col span={12}>
                        <DescriptionItem title="Son Güncelleme" content={dayjs(getDrawerObject?.updated_at).format('DD/MM/YYYY HH:mm:ss')}/>
                    </Col>
                </Row>

                <Divider/>
                <Row>
                    <Col span={24}>
                        <DescriptionItem
                            title="İşlem Notu "
                            content={getDrawerObject?.note === null ? <Tag color={"orange"}>Not Bulunamadı</Tag> : getDrawerObject?.note}
                        />
                    </Col>
                </Row>
                <Divider/>

            </Drawer>


        </>
    )
}

export default ReportAdmin;