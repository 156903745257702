import React from 'react';
import {App, Button, Checkbox, Form, Input} from 'antd';
import {useNavigate} from "react-router-dom";
import {
    LoginOutlined
} from '@ant-design/icons';
import Config from "../../Config";
import dayjs from "dayjs";
import {isMobile} from "react-device-detect";

const AdminLogin = ({onLogin = () => {}}) => {

    const navigate = useNavigate();
    const {message, notification, modal} = App.useApp();

    const onFinish = (values) => {

        const myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");

        const formdata = new FormData();
        formdata.append("email", values.email);
        formdata.append("password", values.password);

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formdata,
            redirect: "follow"
        };

        fetch("https://otobonus.thecoder365.com/api/auth/login", requestOptions)
            .then((response) => response.json())
            .then((result) => {

                if (result.status !== "success") {
                    throw new Error(result.message);
                }

                message.success(result.message);
                window.localStorage.setItem("token", result.data.token);

                onLogin(true);

            })
            .catch((error) => {
                message.error(error.message);
            });

    };

    const onFinishFailed = (errorInfo) => {
        message.error("Giriş bilgilerini doldurunuz");
    };

    return (
        <div style={{width:'100vw',height:'100vh', backgroundColor: '#fff', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundImage: `url(${Config.bg})`, display:"flex", justifyContent:"center", alignItems:"center"}}>

            <div style={{display:"flex", justifyContent:"center", alignItems:"center", background:"#00000038", width:"100vw", height:"100vh"}} >
                <div style={{width: isMobile ? "90%" : 400, background: "white", borderRadius: 16, overflow: "hidden"}}>

                    <div style={{
                        height: 100,
                        background: "rgb(0 0 0 / 7%)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginBottom: 35
                    }}>
                        <img src={Config.logo} height={20} alt={Config.title}/>
                    </div>

                    <div style={{padding: 45, paddingTop: 0, paddingBottom: 15}}>
                        <h1>Yönetim Paneli</h1>
                        <h4>Yetkili bilgileriniz ile giriş yapınız.</h4>

                        <Form
                            style={{marginTop: 35}}
                            name="basic"
                            layout={"vertical"}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off">
                            <Form.Item
                                label="E-Posta"
                                name="email"
                                rules={[{required: true, message: 'E-Posta zorunludur!',},]}>
                                <Input placeholder="E-Posta"/>
                            </Form.Item>

                            <Form.Item
                                label="Şifre"
                                name="password"
                                rules={[{required: true, message: 'Şifre zorunludur!',},]}>
                                <Input.Password placeholder={"******"}/>
                            </Form.Item>


                            <Form.Item label={null} style={{marginTop: 45}}>
                                <Button type="primary" icon={<LoginOutlined/>} block htmlType="submit">
                                    Giriş Yap
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>

                    <div style={{
                        height: 40,
                        background: "rgb(0 0 0 / 7%)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: Config.color
                    }}>
                        {dayjs().format("YYYY")} Dev Team
                    </div>
                </div>
            </div>

        </div>
    )
};
export default AdminLogin;