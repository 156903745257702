import React, {useEffect, useRef, useState} from "react";
import {App, Button, Card, Col, DatePicker, Form, Result, Row, Select, Space, Statistic} from 'antd';
import {DownCircleOutlined, FieldTimeOutlined, FilterOutlined, FireOutlined, UpCircleOutlined, FireTwoTone} from "@ant-design/icons";
import dayjs from "dayjs";
import ReactFrappeChart from "react-frappe-charts";
import CountUp from 'react-countup';
import Config from "../../../Config";

const {RangePicker} = DatePicker;

const formatter = (value) => <CountUp end={value} separator="," />;


const HomeAdmin = () => {

    const {message, notification, modal} = App.useApp();

    const [getFilterView, setFilterview] = useState(true);
    const [getLiveInterval, setLiveInterval] = useState(false);
    const [getDataAnalytics, setDataAnalytics] = useState({});
    const [getFilter, setFilter] = useState(null);
    const liveInterval = useRef(null);
    const [formFilter] = Form.useForm();


    useEffect(() => {
        formFilter.setFieldsValue({
            date: [dayjs().startOf('day'), dayjs().endOf('day')]
        })

        formFilter.submit();
    }, []);

    const startInterval = () => {
        if(getFilter !== null) {
            if (!getLiveInterval) {
                liveInterval.current = setInterval(() => {
                    getReportVies(getFilter)

                }, 3000);
                setLiveInterval(true);
            }
        }else{
            message.error("Filtre Seçiniz");
        }

    };

    const stopInterval = () => {
        clearInterval(liveInterval.current);
        setLiveInterval(false);
    };


    const getReportVies  = (values) => {

        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);
        myHeaders.append("Accept", "application/json");

        const formdata = new FormData();
        formdata.append("start_date", dayjs(values.date[0]).format("DD/MM/YYYY HH:mm:ss"));
        formdata.append("end_date", dayjs(values.date[1]).format("DD/MM/YYYY HH:mm:ss"));
        formdata.append("operation", values.operation);
        formdata.append("category", values.category);

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formdata,
            redirect: "follow"
        };

        fetch("https://otobonus.thecoder365.com/api/admin/bonus/rule/report", requestOptions)
            .then((response) => response.json())
            .then((result) => {

                if(result.status !== "success"){
                    throw new Error(result.message);
                }

                setDataAnalytics(result.data);
            })
            .catch((error) => console.error(error));

    }

    const onFilter = (values) => {

        setFilter(values);

        getReportVies(values);

    }

    return (
        <>


            <Card
                styles={{body: {padding: getFilterView ? "20px 20px" : "0px 20px"}}}
                size={"small"}
                bordered={true}
                title="Filtreleme"
                extra={<Space>


                    <Button
                        size={"small"}
                        onClick={() => {
                            if (!getLiveInterval) {
                                startInterval();
                            } else {
                                stopInterval();
                            }
                        }}
                        type={"primary"}
                        shape={"round"}
                        icon={getLiveInterval ? <FireTwoTone/> : <FireOutlined/>}>
                        Canlı
                    </Button>

                    <Button
                        size={"small"}
                        onClick={() => {
                            setFilterview(!getFilterView);
                        }}
                        type={"link"}
                        shape={"round"}
                        icon={!getFilterView ? <DownCircleOutlined style={{fontSize: 18}}/> :
                            <UpCircleOutlined style={{fontSize: 18}}/>}/>


                </Space>}>


                {
                    getFilterView &&
                    <Form
                        form={formFilter}
                        name="formFilter"
                        layout={"vertical"}
                        onFinish={onFilter}
                        autoComplete="off"
                        initialValues={{
                            operation: "all",
                            category: "all",
                        }}
                    >

                        <Row gutter={[16, 16]}>
                            <Col span={6}>
                                <Form.Item
                                    label="Tarih aralıgı"
                                    name="date"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Tarih Seçiniz!',
                                        },
                                    ]}
                                >
                                    <RangePicker
                                        renderExtraFooter={() => {
                                            return (
                                                <>
                                                    <Row style={{marginTop: 10, marginBottom: 10}} gutter={[16, 16]}>
                                                        <Col span={8}>
                                                            <Button block icon={<FieldTimeOutlined/>} onClick={() => {
                                                                formFilter.setFieldsValue({
                                                                    date: [dayjs().startOf('day'), dayjs().endOf('day')]
                                                                })
                                                            }} type="dashed">
                                                                Bugün
                                                            </Button>
                                                        </Col>
                                                        <Col span={8}>
                                                            <Button block icon={<FieldTimeOutlined/>} onClick={() => {
                                                                formFilter.setFieldsValue({
                                                                    date: [dayjs().subtract(1, 'day').startOf('day'), dayjs().subtract(1, 'day').endOf('day')]
                                                                })
                                                            }} type="dashed">
                                                                Önceki Gün
                                                            </Button>
                                                        </Col>
                                                        <Col span={8}>
                                                            <Button block icon={<FieldTimeOutlined/>} onClick={() => {
                                                                formFilter.setFieldsValue({
                                                                    date: [dayjs().startOf('day'), dayjs().subtract(5, "days").endOf('day')]
                                                                })
                                                            }} type="dashed">
                                                                Son 7 Gün
                                                            </Button>
                                                        </Col>

                                                    </Row>
                                                </>
                                            )
                                        }}
                                        showTime/>
                                </Form.Item>
                            </Col>
                            <Col span={6}>

                                <Form.Item
                                    label="Kategori"
                                    name="category"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Kategori Seçiniz!',
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        placeholder="Seçim Yapınız"
                                        filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        options={[
                                            {value: 'all', label: 'Hepsi'},
                                            {value: 'deposit', label: 'Yatırım'},
                                            {value: 'loss', label: 'Kayıp'},
                                            {value: 'shared', label: 'Shared'},
                                        ]}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={6}>

                                <Form.Item
                                    label="Durum"
                                    name="operation"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Durum Seçiniz!',
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        placeholder="Seçim Yapınız"
                                        filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        options={[
                                            {value: 'all', label: 'Hepsi'},
                                            {value: 'new', label: 'Talep'},
                                            {value: 'order', label: 'Beklemede'},
                                            {value: 'on_process', label: 'İşlemde'},
                                            {value: 'approved', label: 'Onaylandı'},
                                            {value: 'rejected', label: 'Red Edildi'},
                                        ]}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label=" ">
                                    <Button
                                        size={"small"}
                                        onClick={() => {
                                            formFilter.submit();
                                        }}
                                        type={"primary"}
                                        shape={"round"}
                                        icon={<FilterOutlined/>}>Filtrele</Button>
                                </Form.Item>
                            </Col>
                        </Row>

                    </Form>
                }
            </Card>


            {
                Object.keys((getDataAnalytics?.count ?? {})).length == 0 &&
                <div style={{flex:1, display:"flex", justifyContent: 'center', alignItems: 'center', height:"70vh"}}>
                    <Result
                        title="Lütfen Bekleyiniz"
                    />
                </div>
            }
            {
                Object.keys((getDataAnalytics?.count ?? {})).length > 0 &&
                    <h1>
                        İşlemler
                    </h1>
            }

            <Row style={{marginTop: 20}} gutter={[16, 16]}>

                {
                    Object.keys((getDataAnalytics?.count ?? {})).map(itemMain => {

                        const labelsCategory = {
                            shared: "Paylaşımlı (İŞLEM)",
                            deposit: "Yatırım (İŞLEM)",
                            loss: "Kayıp (İŞLEM)",
                        };
                        const labelsOperation = {
                            "new": "Talep",
                            "order": "Beklemede",
                            "on_process": "İşlemde",
                            "approved": "Onaylandı"
                            , "rejected": "Red"
                        };
                        return (
                            <Col span={8}>
                                <Card bordered title={labelsCategory[itemMain]} size={"small"}>

                                    <ReactFrappeChart
                                        type="bar"
                                        colors={[Config.color]}
                                        axisOptions={{xAxisMode: "tick", yAxisMode: "tick", xIsSeries: 1}}
                                        height={250}
                                        data={{
                                            labels: Object.keys(getDataAnalytics?.count[itemMain] ?? {}).map(itemLb => labelsOperation[itemLb]),
                                            datasets: [{values: Object.keys(getDataAnalytics?.count[itemMain] ?? {}).map(itemLb => itemLb).map(itemAmount => getDataAnalytics?.count[itemMain][itemAmount])}],
                                        }}
                                    />
                                </Card>
                            </Col>
                        )
                    })
                }

            </Row>

            <Row style={{marginTop: 20}} gutter={[16, 16]}>

                {
                    Object.keys((getDataAnalytics?.price ?? {})).map(itemMain => {

                        const labelsCategory = {
                            shared: "Paylaşımlı (KASA)",
                            deposit: "Yatırım  (KASA)",
                            loss: "Kayıp  (KASA)",
                        };
                        const labelsOperation = {
                            "new": "Talep",
                            "order": "Beklemede",
                            "on_process": "İşlemde",
                            "approved": "Onaylandı",
                            "rejected": "Red"
                        };
                        return (
                            <Col span={8}>
                                <Card bordered title={labelsCategory[itemMain]} size={"small"}>

                                    <ReactFrappeChart
                                        type="bar"
                                        colors={[Config.color]}
                                        axisOptions={{xAxisMode: "tick", yAxisMode: "tick", xIsSeries: 1}}
                                        height={250}
                                        data={{
                                            labels: Object.keys(getDataAnalytics?.price[itemMain] ?? {}).map(itemLb => labelsOperation[itemLb]),
                                            datasets: [{values: Object.keys(getDataAnalytics?.price[itemMain] ?? {}).map(itemLb => itemLb).map(itemAmount => getDataAnalytics?.price[itemMain][itemAmount])}],
                                        }}
                                    />
                                </Card>
                            </Col>
                        )
                    })
                }

            </Row>


        </>
    )
}

export default HomeAdmin;