import React, {useEffect, useState} from "react";
import {App, Button, Card, Col, Drawer, Form, Input, InputNumber, Row, Select, Space, Table, Tag} from "antd";
import {EditOutlined, PlusOutlined} from '@ant-design/icons';
import dayjs from "dayjs";


const Deposit = () => {


    const {message, notification, modal} = App.useApp();
    const [getRules, setRules] = useState([]);
    const [getRulesDeposit, setRulesDeposit] = useState([]);
    const [getRulesLoss, setRulesLoss] = useState([]);
    const [getRuleLoader, setRuleLoader] = useState(true);
    const [getRuleCreateDrawer, setRuleCreateDrawer] = useState(false);
    const [getRuleEditDrawer, setRuleEditDrawer] = useState(false);
    const [getRuleEditDrawerObject, setRuleEditDrawerObject] = useState(null);
    const [getRemoteBonusIDS, setRemoteBonusIDS] = useState([]);
    const [form] = Form.useForm();
    const [formEdit] = Form.useForm();



    const getLoadData = () => {

        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);
        myHeaders.append("Accept", "application/json");


        setRuleLoader(true);
        fetch("https://otobonus.thecoder365.com/api/admin/bonus/rules/deposit", {
            method: "GET",
            headers: myHeaders
        })
            .then((response) => response.json())
            .then((result) => {
                setRuleLoader(false);
                setRules(result.data);
            })
            .catch((error) => {
                setRuleLoader(false);
                console.error(error)
            });

        fetch("https://otobonus.thecoder365.com/api/admin/bonus/rules/deposit", {
            method: "GET",
            headers: myHeaders
        })
            .then((response) => response.json())
            .then((result) => {
                setRulesDeposit(result.data);
            })
            .catch((error) => {
                console.error(error)
            });

        fetch("https://otobonus.thecoder365.com/api/admin/bonus/rules/loss", {
            method: "GET",
            headers: myHeaders
        })
            .then((response) => response.json())
            .then((result) => {
                setRulesLoss(result.data);
            })
            .catch((error) => {
                console.error(error)
            });

        fetch("https://otobonus.thecoder365.com/api/admin/bonus/remote/list", {
            method: "POST",
            headers: myHeaders
        })
            .then((response) => response.json())
            .then((result) => {
                setRemoteBonusIDS(result.data);
            })
            .catch((error) => {
                console.error(error)
            });

    }
    useEffect(getLoadData, []);


    const columns = [
        {
            title: 'Kural',
            dataIndex: 'title',
            key: 'title',
            width: 200,
        },
        {
            title: 'Durum',
            dataIndex: 'status',
            key: 'status',
            render: (status, record) => status === "active" ? <Tag color={"green"}>AKTİF</Tag> : <Tag color={"red"}>PASİF</Tag>,
            width: 100,
        },
        {
            title: 'Hesaplama',
            dataIndex: 'value_type',
            key: 'value_type',
            render: (status, record) => status === "percent" ? <Tag color={"blue"}>YÜZDE ({record.value_amount}%)</Tag> : <Tag color={"purple"}>TUTAR {record.value_amount}₺</Tag>
        },
        {
            title: 'Oluşturma',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (created_at, record) => dayjs(created_at).format('DD/MM/YYYY HH:mm:ss'),
        },
        {
            title: 'Güncelleme',
            dataIndex: 'updated_at',
            key: 'updated_at',
            render: (updated_at, record) => dayjs(updated_at).format('DD/MM/YYYY HH:mm:ss'),
        },
        {
            title: 'İşlemler',
            dataIndex: 'id',
            key: 'id',
            render: (status, record) =>
                <Button
                    size={"small"}
                    onClick={() => {

                        setRuleEditDrawer(true);
                        setRuleEditDrawerObject(record);
                        formEdit.resetFields();

                        formEdit.setFieldsValue({
                            title: record?.title,
                            bonus_id: record?.bonus_id,
                            category: "deposit",
                            multiple: "passive",
                            prev_date: record?.prev_date,
                            min_amount: parseInt(record?.min_amount),
                            max_amount: parseInt(record?.max_amount),
                            nd_step: String(record?.nd_step),
                            value_type: record?.value_type,
                            value_amount: parseFloat(record?.value_amount),
                            status: record?.status
                        })

                    }}
                    type={"default"}
                    shape={"round"}
                    icon={<EditOutlined/>}
                >Düzenle</Button>
        }
    ];


    const createNewRuleAction = (values) => {

        const formdata = new FormData();
        formdata.append("title", values.title);
        formdata.append("bonus_id", values.bonus_id);
        formdata.append("category", "deposit");
        formdata.append("multiple", "passive");
        formdata.append("prev_date", values.prev_date);
        formdata.append("min_amount", values.min_amount);
        formdata.append("max_amount", values.max_amount);
        formdata.append("nd_step", values.nd_step);
        formdata.append("value_type", values.value_type);
        formdata.append("value_amount", values.value_amount);
        formdata.append("shared", `[]`);
        formdata.append("status", values.status);

        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);
        myHeaders.append("Accept", "application/json");

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formdata,
        };

        fetch("https://otobonus.thecoder365.com/api/admin/bonus/rule/create", requestOptions)
            .then((response) => response.json())
            .then((result) => {

                if (result.status !== "success") {
                    throw new Error(result.message);
                }

                message.success(result.message);

                getLoadData();

                form.resetFields();
                setRuleCreateDrawer(false);

            })
            .catch((error) => {
                message.error(error.message);
            });

    }


    const editNewRuleAction = (values) => {

        const formdata = new FormData();
        formdata.append("title", values.title);
        formdata.append("bonus_id", values.bonus_id);
        formdata.append("category", "deposit");
        formdata.append("multiple", "passive");
        formdata.append("prev_date", values.prev_date);
        formdata.append("min_amount", values.min_amount);
        formdata.append("max_amount", values.max_amount);
        formdata.append("nd_step", values.nd_step);
        formdata.append("value_type", values.value_type);
        formdata.append("value_amount", values.value_amount);
        formdata.append("shared", `[]`);
        formdata.append("status", values.status);

        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);
        myHeaders.append("Accept", "application/json");

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formdata,
        };

        fetch(`https://otobonus.thecoder365.com/api/admin/bonus/rule/edit/${getRuleEditDrawerObject?.id}`, requestOptions)
            .then((response) => response.json())
            .then((result) => {

                if (result.status !== "success") {
                    throw new Error(result.message);
                }

                message.success(result.message);

                getLoadData();

                if(values.status === "archived") {
                    setRuleEditDrawer(false);
                }

            })
            .catch((error) => {
                message.error(error.message);
            });

    }


    return (
        <>
            <Card
                size={"small"}
                loading={getRuleLoader}
                bordered={true}
                title="Yatırım kuralları"
                extra={<Space>
                    <Button
                        size={"small"}
                        onClick={() => {
                            setRuleCreateDrawer(true);
                        }}
                        loading={getRuleLoader}
                        type={"default"}
                        shape={"round"}
                        icon={<PlusOutlined/>}>Kural Ekle</Button>
                </Space>}>
                <Table size={"small"} loading={getRuleLoader} dataSource={getRules} columns={columns}/>
            </Card>


            <Drawer
                width={600}
                title="Yeni yatırım kuralı"
                onClose={() => {
                    setRuleCreateDrawer(false);
                }}
                extra={<Space>
                    <Button
                        size={"small"}
                        onClick={() => {
                            form.submit();
                        }}
                        loading={getRuleLoader}
                        type={"default"}
                        shape={"round"}
                        icon={<PlusOutlined/>}>Oluştur</Button>
                </Space>}
                open={getRuleCreateDrawer}>

                <Form
                    form={form}
                    onFinish={createNewRuleAction}
                    layout="vertical"
                    autoComplete="off"
                >

                    <Row gutter={[10]}>

                        {/* Title */}
                        <Col span={24}>
                            <Form.Item
                                label="Başlık"
                                name="title"
                                rules={[{required: true, message: 'Başlık girin', min: 6}]}
                            >
                                <Input placeholder="Başlık"/>
                            </Form.Item>
                        </Col>

                        {/* Bonus ID */}
                        <Col span={24}>
                            <Form.Item
                                label="Bonus"
                                name="bonus_id"
                                rules={[{required: true, message: 'Durum seçin'}]}>
                                <Select
                                    showSearch
                                    optionFilterProp="label"
                                    options={getRemoteBonusIDS?.map(item => ({
                                        key: item.id,
                                        value: item.id,
                                        label: item.title
                                    }))}
                                    placeholder="Bonus Seçin"/>
                            </Form.Item>
                        </Col>

                        {/* Prev Date */}
                        <Col span={24}>
                            <Form.Item
                                label="Son işlem Tarih"
                                name="prev_date"
                                rules={[{required: true, message: 'Tarih seçin'}]}>
                                <Select placeholder="Tarih Seçin">
                                    <Select.Option value="day_1">Son 24 saat</Select.Option>
                                    <Select.Option value="day_2">Son 2 gün</Select.Option>
                                    <Select.Option value="day_3">Son 3 gün</Select.Option>
                                    <Select.Option value="day_4">Son 4 gün</Select.Option>
                                    <Select.Option value="day_5">Son 5 gün</Select.Option>
                                    <Select.Option value="day_6">Son 6 gün</Select.Option>
                                    <Select.Option value="day_7">Son 7 gün</Select.Option>
                                    <Select.Option value="day_8">Son 8 gün</Select.Option>
                                    <Select.Option value="day_9">Son 9 gün</Select.Option>
                                    <Select.Option value="day_10">Son 10 gün</Select.Option>
                                    <Select.Option value="day_15">Son 15 gün</Select.Option>
                                    <Select.Option value="day_30">Son 30 gün</Select.Option>
                                    <Select.Option value="day_45">Son 45 gün</Select.Option>
                                    <Select.Option value="day_60">Son 60 gün</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>

                        {/* Minimum Amount */}
                        <Col span={12}>
                            <Form.Item
                                label="Minimum Tutar"
                                name="min_amount"
                                rules={[{required: true, message: 'Minimum tutar girin', type: 'number', min: 0}]}
                            >
                                <InputNumber style={{width: "100%"}} className="w-100" placeholder="Minimum Tutar"/>
                            </Form.Item>
                        </Col>

                        {/* Maximum Amount */}
                        <Col span={12}>
                            <Form.Item
                                label="Maksimum Tutar"
                                name="max_amount"
                                rules={[{required: true, message: 'Maksimum tutar girin', type: 'number', max: 100000}]}
                            >
                                <InputNumber style={{width: "100%"}} className="w-100" placeholder="Maksimum Tutar"/>
                            </Form.Item>
                        </Col>

                        {/* Value Type */}
                        <Col span={12}>
                            <Form.Item
                                label="Değer Tipi"
                                name="value_type"
                                rules={[{required: true, message: 'Değer tipi seçin'}]}
                            >
                                <Select placeholder="Değer Tipi Seçin">
                                    <Select.Option value="percent">Yüzde</Select.Option>
                                    <Select.Option value="amount">Tutar</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>

                        {/* Value Amount */}
                        <Col span={12}>
                            <Form.Item
                                label="Değer Miktarı"
                                name="value_amount"
                                rules={[{required: true, message: 'Değer miktarı girin', type: 'number'}]}
                            >
                                <InputNumber style={{width: "100%"}} className="w-100" placeholder="Değer Miktarı"/>
                            </Form.Item>
                        </Col>

                        {/* 2nd Step */}
                        <Col span={12}>
                            <Form.Item
                                label="Incı Yatırım"
                                name="nd_step"
                                rules={[{required: true, message: 'Incı seçiniz'}]}
                            >
                                <Select placeholder="Incı seçiniz">
                                    <Select.Option value="0">Hayır</Select.Option>
                                    <Select.Option value="1">1. Yatırım</Select.Option>
                                    <Select.Option value="2">2. Yatırım</Select.Option>
                                    <Select.Option value="3">3. Yatırım</Select.Option>
                                    <Select.Option value="4">4. Yatırım</Select.Option>
                                    <Select.Option value="5">5. Yatırım</Select.Option>
                                    <Select.Option value="6">6. Yatırım</Select.Option>
                                    <Select.Option value="7">7. Yatırım</Select.Option>
                                    <Select.Option value="8">8. Yatırım</Select.Option>
                                    <Select.Option value="9">9. Yatırım</Select.Option>
                                    <Select.Option value="10">10. Yatırım</Select.Option>
                                    <Select.Option value="20">20. Yatırım</Select.Option>
                                    <Select.Option value="40">40. Yatırım</Select.Option>
                                    <Select.Option value="50">50. Yatırım</Select.Option>
                                    <Select.Option value="100">100. Yatırım</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>

                        {/* Status */}
                        <Col span={12}>
                            <Form.Item
                                label="Durum"
                                name="status"
                                rules={[{required: true, message: 'Durum seçin'}]}>
                                <Select placeholder="Durum Seçin">
                                    <Select.Option value="active">Aktif</Select.Option>
                                    <Select.Option value="passive">Pasif</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>


                    </Row>
                </Form>

            </Drawer>



            <Drawer
                width={600}
                title={`${getRuleEditDrawerObject?.title ?? "Yatırım"} Düzenleme`}
                onClose={() => {
                    setRuleEditDrawer(false);
                    setRuleEditDrawerObject(null)
                    formEdit.resetFields();
                }}
                extra={<Space>
                    <Button
                        onClick={() => {
                            formEdit.submit();
                        }}
                        loading={getRuleLoader}
                        type={"default"}
                        shape={"round"}
                        size={"small"}
                        icon={<EditOutlined/>}>Güncelle</Button>
                </Space>}
                open={getRuleEditDrawer}>

                <Form
                    form={formEdit}
                    onFinish={editNewRuleAction}
                    layout="vertical"
                    autoComplete="off"
                >

                    <Row gutter={[10]}>

                        {/* Title */}
                        <Col span={24}>
                            <Form.Item
                                label="Başlık"
                                name="title"
                                rules={[{required: true, message: 'Başlık girin', min: 6}]}
                            >
                                <Input placeholder="Başlık"/>
                            </Form.Item>
                        </Col>

                        {/* Bonus ID */}
                        <Col span={24}>
                            <Form.Item
                                label="Bonus"
                                name="bonus_id"
                                rules={[{required: true, message: 'Durum seçin'}]}>
                                <Select
                                    showSearch
                                    optionFilterProp="label"
                                    options={getRemoteBonusIDS?.map(item => ({
                                        key: item.id,
                                        value: item.id,
                                        label: item.title
                                    }))}
                                    placeholder="Bonus Seçin"/>
                            </Form.Item>
                        </Col>


                        {/* Prev Date */}
                        <Col span={24}>
                            <Form.Item
                                label="Son işlem Tarih"
                                name="prev_date"
                                rules={[{required: true, message: 'Tarih seçin'}]}>
                                <Select placeholder="Tarih Seçin">
                                    <Select.Option value="day_1">Son 24 saat</Select.Option>
                                    <Select.Option value="day_2">Son 2 gün</Select.Option>
                                    <Select.Option value="day_3">Son 3 gün</Select.Option>
                                    <Select.Option value="day_4">Son 4 gün</Select.Option>
                                    <Select.Option value="day_5">Son 5 gün</Select.Option>
                                    <Select.Option value="day_6">Son 6 gün</Select.Option>
                                    <Select.Option value="day_7">Son 7 gün</Select.Option>
                                    <Select.Option value="day_8">Son 8 gün</Select.Option>
                                    <Select.Option value="day_9">Son 9 gün</Select.Option>
                                    <Select.Option value="day_10">Son 10 gün</Select.Option>
                                    <Select.Option value="day_15">Son 15 gün</Select.Option>
                                    <Select.Option value="day_30">Son 30 gün</Select.Option>
                                    <Select.Option value="day_45">Son 45 gün</Select.Option>
                                    <Select.Option value="day_60">Son 60 gün</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>

                        {/* Minimum Amount */}
                        <Col span={12}>
                            <Form.Item
                                label="Minimum Tutar"
                                name="min_amount"
                                rules={[{required: true, message: 'Minimum tutar girin', type: 'number', min: 0}]}
                            >
                                <InputNumber style={{width: "100%"}} className="w-100" placeholder="Minimum Tutar"/>
                            </Form.Item>
                        </Col>

                        {/* Maximum Amount */}
                        <Col span={12}>
                            <Form.Item
                                label="Maksimum Tutar"
                                name="max_amount"
                                rules={[{required: true, message: 'Maksimum tutar girin', type: 'number', max: 100000}]}
                            >
                                <InputNumber style={{width: "100%"}} className="w-100" placeholder="Maksimum Tutar"/>
                            </Form.Item>
                        </Col>

                        {/* Value Type */}
                        <Col span={12}>
                            <Form.Item
                                label="Değer Tipi"
                                name="value_type"
                                rules={[{required: true, message: 'Değer tipi seçin'}]}
                            >
                                <Select placeholder="Değer Tipi Seçin">
                                    <Select.Option value="percent">Yüzde</Select.Option>
                                    <Select.Option value="amount">Tutar</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>

                        {/* Value Amount */}
                        <Col span={12}>
                            <Form.Item
                                label="Değer Miktarı"
                                name="value_amount"
                                rules={[{required: true, message: 'Değer miktarı girin', type: 'number'}]}
                            >
                                <InputNumber style={{width: "100%"}} className="w-100" placeholder="Değer Miktarı"/>
                            </Form.Item>
                        </Col>

                        {/* 2nd Step */}
                        <Col span={12}>
                            <Form.Item
                                label="Incı Yatırım"
                                name="nd_step"
                                rules={[{required: true, message: 'Incı seçiniz'}]}
                            >
                                <Select placeholder="Incı seçiniz">
                                    <Select.Option value="0">Hayır</Select.Option>
                                    <Select.Option value="1">1. Yatırım</Select.Option>
                                    <Select.Option value="2">2. Yatırım</Select.Option>
                                    <Select.Option value="3">3. Yatırım</Select.Option>
                                    <Select.Option value="4">4. Yatırım</Select.Option>
                                    <Select.Option value="5">5. Yatırım</Select.Option>
                                    <Select.Option value="6">6. Yatırım</Select.Option>
                                    <Select.Option value="7">7. Yatırım</Select.Option>
                                    <Select.Option value="8">8. Yatırım</Select.Option>
                                    <Select.Option value="9">9. Yatırım</Select.Option>
                                    <Select.Option value="10">10. Yatırım</Select.Option>
                                    <Select.Option value="20">20. Yatırım</Select.Option>
                                    <Select.Option value="40">40. Yatırım</Select.Option>
                                    <Select.Option value="50">50. Yatırım</Select.Option>
                                    <Select.Option value="100">100. Yatırım</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>

                        {/* Status */}
                        <Col span={12}>
                            <Form.Item
                                label="Durum"
                                name="status"
                                rules={[{required: true, message: 'Durum seçin'}]}>
                                <Select placeholder="Durum Seçin">
                                    <Select.Option value="active">Aktif</Select.Option>
                                    <Select.Option value="passive">Pasif</Select.Option>
                                    <Select.Option value="archived">Yayından Kaldır</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>


                    </Row>
                </Form>

            </Drawer>


        </>
    )
}

export default Deposit;