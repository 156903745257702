import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { App as AntApp,ConfigProvider, Result, Button } from 'antd';
import Config from "./Config";
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import Guard from "./admin/guard";
import AdminTemplate from "./admin/AdminTemplate";
import HomeAdmin from "./admin/pages/HomeAdmin";
import ReportAdmin from "./admin/pages/ReportAdmin";
import ManagementAdmin from "./admin/pages/ManagementAdmin";
import { HomeOutlined, UserOutlined } from '@ant-design/icons';


const uuidV4 = () =>  {
    const uuid = new Array(36);
    for (let i = 0; i < 36; i++) {
        uuid[i] = Math.floor(Math.random() * 16);
    }
    uuid[14] = 4; // set bits 12-15 of time-high-and-version to 0100
    uuid[19] = uuid[19] &= ~(1 << 2); // set bit 6 of clock-seq-and-reserved to zero
    uuid[19] = uuid[19] |= (1 << 3); // set bit 7 of clock-seq-and-reserved to one
    uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
    return uuid.map((x) => x.toString(16)).join('');
}

window.uuid = uuidV4;

const router = createBrowserRouter([
    {
        path: "/",
        element: <App/>,
        handle: {
            crumb: (data) => "Bonus",
        }
    },
    {
        path: "/admin",
        element: (
            <Guard>
                <AdminTemplate/>
            </Guard>
        ),
        handle: {
            crumb: {
                href: '/admin',
                title: <><HomeOutlined /> Yönetim </>,
            }
        },
        children: [
            {
                path: "",
                element: <HomeAdmin/>,
                handle: {
                    crumb:  {
                        href: '/admin',
                        title: "Genel Bakış"
                    }
                }
            },
            {
                path: "report",
                element: <ReportAdmin/>,
                handle: {
                    crumb: {
                        href: '/admin/report',
                        title: "Rapor - Canlı Akış",
                    }
                }
            },
            {
                path: "management",
                element: <ManagementAdmin/>,
                handle: {
                    crumb: {
                        href: '/admin/management',
                        title: "Bonus Yönetimi"
                    }
                }
            }
        ]
    },
    {
        path: "*",
        element:
            <div style={{width:"100vw", height:"100vh", display:"flex", justifyContent:"center", alignItems:"center"}}>
                <Result
                    status="404"
                    title="Sayfa Bulunamadı"
                    subTitle="Aradğınız sayfa bulunamadı, anlık gelişen bir sorun bu işlemi tetiklemiş olabilir."
                    extra={<Button type="primary" onClick={() => window.history.back(-1)}>Geri Dön</Button>}
                />
            </div>
    },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ConfigProvider theme={{
        token: {
            "colorPrimary": Config.color,
            "colorInfo": Config.color,
            "fontSize": 14,
            "borderRadius": 16,
            "wireframe": true,
            "colorTextBase": "#131313",
            "colorBgBase": "#f6f7f8",
            "colorSuccess": "#13c2c2",
            "colorError": Config.color,
            "colorText": "#161616e0"
        }
    }}>
        <AntApp>
            <RouterProvider router={router} />
        </AntApp>
    </ConfigProvider>
);

reportWebVitals();
