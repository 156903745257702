import React, {useEffect, useState} from "react";
import {App as AntApp, Button, Col, Card, Descriptions, Form, Input, Result, Row, Select, Space, Steps} from "antd";
import {SubnodeOutlined, UserOutlined, ArrowRightOutlined, ArrowLeftOutlined, LoadingOutlined} from "@ant-design/icons";
import {isMobile} from "react-device-detect";


const BonusHistories = ({username}) => {

    const {message, notification, modal} = AntApp.useApp();

    const [getLoader, setLoader] = useState(false);
    const [getHistories, setHistories] = useState([]);
    const [getStep, setStep] = useState(0);
    const [form] = Form.useForm();

    useEffect(() => {

        if(username !== null){
            setLoader(true);
            form.setFieldsValue({
                username : username,
            });
            OnClickNext();

        }

    }, [username]);

    const OnClickNext = () => {

        form.validateFields().then(async (values) => {

            if(getStep === 0){

                const formdata = new FormData();
                formdata.append("username", values.username);
                setLoader(true);
                fetch("https://otobonus.thecoder365.com/api/public/history/username", {
                    method: "POST",
                    body: formdata,
                    redirect: "follow"
                })
                    .then((response) => response.json())
                    .then((result) => {

                        if(result.status !== "success"){
                            throw new Error(result.message);
                        }

                        setLoader(false);
                        setStep(getStep + 1)
                        setHistories(result.data);

                    })
                    .catch((error) => {
                        setLoader(false);
                        notification.error({
                            message: "HATA",
                            description: error.message,
                            role: "alert",
                            placement: "top"
                        });
                    });

            }

        })
            .catch(() => {
                notification.error({
                    message: "HATA",
                    description: "Lütfen bilgileri eksiksiz giriniz",
                    role: "alert",
                    placement: "top"
                });
            })

    }

    if(getLoader){
        return (
            <Result
                icon={<LoadingOutlined />}
                title="Lütfen Bekleyiniz"
            />
        )
    }

    return(
        <div style={{paddingTop:10}}>

            {
                getStep < 1 &&
                    <Steps
                        direction={"horizontal"}
                        type={"default"}
                        responsive={false}
                        activeStep={getStep}
                        size={"small"}
                        current={getStep}
                        items={[
                            {
                                title: 'Giriş',
                                status: getStep >= 0 ? "finish" : "process"
                            },
                            {
                                title: 'Hesap Geçmişi',
                                status: getStep >= 0 ? "finish" : "process"
                            },
                        ]}
                    />
            }


            <Form
                form={form}
                size={"large"}
                style={{marginTop:(getStep < 1 ? 35 : 0)}}
                layout="vertical"
                name="basic"
                onFinish={() => {
                }}
                onFinishFailed={() => {
                }}
                autoComplete="off">

                <Row gutter={[10, 10]}>

                    {
                        (getStep === 0) &&
                        <Col span={18}>
                            <Form.Item
                                label="Kullanıcı Adı"
                                name="username"
                                rules={[{required: true, message: 'Zorunlu Alan!'}]}>
                                <Input prefix={<UserOutlined/>} placeholder={"Kullanıcı adınızı giriniz..."}/>
                            </Form.Item>
                        </Col>
                    }

                    {
                        (getStep === 0) &&
                        <Col span={6}>
                            <Form.Item
                                rules={[{required: true, message: 'Zorunlu Alan!'}]}>
                                <Button
                                    style={{flex:6, marginTop:30}}
                                    icon={<ArrowRightOutlined />}
                                    type="primary"
                                    block
                                    onClick={OnClickNext}>
                                    Sorgula
                                </Button>
                            </Form.Item>

                        </Col>
                    }

                    {
                        (getStep === 1) &&
                        <>

                            <Col span={24} style={{marginBottom:20}}>
                                <Card bordered={false} styles={{ body: {padding:10} }} title={"Kullanıcı Bilgileri"} bodyStyle={{padding:5, paddingTop:10, paddingBottom:10}}>
                                    <Descriptions bordered size={"small"}>
                                        <Descriptions.Item span={4}   label="Kullanıcı Adı">{getHistories?.username}</Descriptions.Item>
                                        <Descriptions.Item  span={2}  label="İsim">{getHistories?.FirstName}</Descriptions.Item>
                                        <Descriptions.Item  span={2}  label="Soyisim">{getHistories?.LastName}</Descriptions.Item>
                                        <Descriptions.Item  span={2}  label="Bakiye">{getHistories?.balance?.toFixed(2)} ₺</Descriptions.Item>
                                        <Descriptions.Item  span={2}  label="Site">{getHistories?.PartnerName}</Descriptions.Item>
                                    </Descriptions>
                                </Card>
                            </Col>


                        <Col span={24}>
                            <Card bordered={false} title={"Bonus Talepleri"} bodyStyle={{padding:5, paddingTop:10, paddingBottom:10}}>
                                {
                                    (getHistories?.histories ?? []).map(item => (
                                        <Col span={24} style={{marginBottom:10}}>
                                            <Descriptions size={"small"} bordered>
                                                <Descriptions.Item span={12}  label="Bonus">{item.title}</Descriptions.Item>
                                                <Descriptions.Item  span={12}  label="Hesaplama">{item.description}</Descriptions.Item>
                                                <Descriptions.Item  span={12}  label="Tutar">{item.calculate.toFixed(2)} ₺</Descriptions.Item>
                                                <Descriptions.Item  span={2}  label="Tür">
                                                    {{
                                                        loss: "Kayıp Bonusu",
                                                        deposit: "Yatırım Bonusu",
                                                        shared: "Sahared Bonusu",
                                                    }[item.category]}
                                                </Descriptions.Item>
                                                <Descriptions.Item  span={2}  label="Durum">
                                                    {{
                                                        "new"        : "Sipariş içeriği gösterimi",
                                                        "order"      : "Mevcut sipariş işlem sırasında",
                                                        "on_process" : "Mevcut sipariş işlemde",
                                                        "approved"   : "Sipariş tamamlandı",
                                                        "rejected"   : "Sipariş iptal edildi"
                                                    }[item.operation]}
                                                </Descriptions.Item>
                                                <Descriptions.Item  span={2}  label="Kayıt">{item.created_at}</Descriptions.Item>
                                                <Descriptions.Item  span={2}  label="Güncelleme">{item.updated_at}</Descriptions.Item>

                                            </Descriptions>
                                        </Col>
                                    ))
                                }

                                {
                                    (getHistories?.histories ?? []).length === 0 &&
                                        <Result
                                            title="Kayıt Bulunamadı"
                                        />
                                }

                            </Card>
                        </Col>

                        </>
                    }


                </Row>

            </Form>

        </div>
    )
}


export default BonusHistories;