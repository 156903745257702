import React, { useState } from "react";
import {BrowserRouter, Routes, Route, useNavigate, Outlet, useMatches} from "react-router-dom";
import { AppContainer, NavBar, NavBarLink, AppTheme, NavPageContainer } from "react-windows-ui";

import "react-windows-ui/config/app-config.css";
import "react-windows-ui/dist/react-windows-ui.min.css";
import "react-windows-ui/icons/winui-icons.min.css";
import Config from "../Config";
import {App, Breadcrumb, Button, Drawer, Form, Input, Space} from "antd";
import {EditOutlined, LoginOutlined, PlusOutlined} from '@ant-design/icons';


const AdminTemplate = () => {

    const {message, notification, modal} = App.useApp();
    const navigate = useNavigate();
    const [route, setRoute] = useState(window.location.pathname);

    const [getPasswordDrawer, setPasswordDrawer] = useState(false);
    const [getPasswordLoader, setPasswordLoader] = useState(false);


    const _navigate = (route) => {
        navigate(route);
        setRoute(route);
    }
    let matches = useMatches();
    let crumbs = matches
        .filter((match) => Boolean(match.handle?.crumb))
        .map((match) => (match.handle.crumb));



    const logOutAction = (values) => {

        const formdata = new FormData();


        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);
        myHeaders.append("Accept", "application/json");

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formdata,
        };

        fetch("https://otobonus.thecoder365.com/api/admin/logout", requestOptions)
            .then((response) => response.json())
            .then((result) => {

                if (result.status !== "success") {
                    throw new Error(result.message);
                }

                window.localStorage.removeItem("token");

                message.success("Oturum kapatıldı");
                navigate("/");


            })
            .catch((error) => {
                message.error(error.message);
            });

    }



    const resetPassword = (values) => {

        const formdata = new FormData();

        formdata.append("password", values.password);

        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);
        myHeaders.append("Accept", "application/json");

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formdata,
        };

        setPasswordLoader(true)
        fetch("https://otobonus.thecoder365.com/api/admin/logout", requestOptions)
            .then((response) => response.json())
            .then((result) => {

                if (result.status !== "success") {
                    throw new Error(result.message);
                }

                window.localStorage.removeItem("token");

                setPasswordLoader(false)
                logOutAction();
                message.success("Şifre güncellendi ve oturum kapatıldı");
                navigate("/");


            })
            .catch((error) => {
                setPasswordLoader(false)
                message.error(error.message);
            });

    }

    return (
        <>

            <AppTheme
                scheme={"light"}
                color={Config.color}
                colorDarkMode={Config.color}
                onColorChange={() => {}}
                onSchemeChange={() => {}}
            />
            <AppContainer>



                <NavBar
                    goBack={() => {  }}
                    title={Config.title}
                    shadowOnScroll={true}
                    titleBarMobile={<div><span className="ui-navbar-name">{Config.title}</span></div>}>
                    <div style={{display:"flex", width:'100%', height:"100%",flexDirection:"column"}} >
                        <div style={{flex:1}}>
                            <h1>Sayfalar</h1>
                            <div className="ui-hr"></div>
                            <NavBarLink
                                text="Anasayfa"
                                active={route === "/admin" ?? true}
                                onClick={() => _navigate("/admin")}
                                icon={<i className="icons10-home"></i>}
                            />
                            <NavBarLink
                                text="Canlı"
                                active={route === "/admin/report" ?? true}
                                onClick={() => _navigate("/admin/report")}
                                icon={<i className="icons10-sync"></i>}
                            />
                            <NavBarLink
                                text="Yönetim"
                                active={route === "/admin/management" ?? true}
                                onClick={() => _navigate("/admin/management")}
                                icon={<i className="icons10-parallel-tasks"></i>}
                            />
                        </div>
                        <div style={{width:"100%"}}>
                            <NavBarLink
                                text="Şifre Güncelle"
                                active={false}
                                onClick={() => {
                                    setPasswordDrawer(true);
                                }}
                                icon={<i className="icons10-key"></i>}
                            />
                            <NavBarLink
                                text="Oturum Kapat"
                                active={false}
                                onClick={logOutAction}
                                icon={<i className="icons10-lock"></i>}
                            />
                        </div>
                    </div>
                </NavBar>

                <NavPageContainer animateTransition={false} overscroll={true} hasPadding={true}>
                    <div style={{width:"100%", height:70, display:"flex", alignItems:"center"}}>
                        <Breadcrumb items={crumbs} />
                    </div>
                    <Outlet/>
                </NavPageContainer>

                <Drawer
                    width={400}
                    title={"Şifre Güncelleme"}
                    onClose={() => {
                        setPasswordDrawer(false);
                    }}
                    open={getPasswordDrawer}>

                    <Form
                        disabled={getPasswordLoader}
                        name="basic"
                        layout={"vertical"}
                        onFinish={resetPassword}
                        autoComplete="off">

                        <Form.Item
                            label="Şifre"
                            name="password"
                            rules={[{required: true, message: 'Şifre zorunludur!',},{min: 6, message: 'Minimum 6 karekter!',}]}>
                            <Input.Password placeholder={"******"}/>
                        </Form.Item>


                        <Form.Item label={null} style={{marginTop: 45}}>
                            <Button type="primary" icon={<LoginOutlined/>} block htmlType="submit">
                                Şifre Güncelle & Çıkış Yap
                            </Button>
                        </Form.Item>
                    </Form>

                </Drawer>

            </AppContainer>
        </>
    )
}

export default AdminTemplate;