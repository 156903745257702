import React, {useEffect, useState} from "react";
import {App as AntApp, Button, Col, Descriptions, Form, Input, Result, Row, Select, Steps, Tag} from "antd";
import {
    ArrowLeftOutlined,
    ArrowRightOutlined,
    CheckCircleOutlined, LoadingOutlined,
    SubnodeOutlined,
    UserOutlined
} from "@ant-design/icons";


const BonusRequest = ({username}) => {

    const {message, notification, modal} = AntApp.useApp();

    const [getBonusToken, setBonusToken] = useState(null);
    const [getLoader, setLoader] = useState(false);
    const [getStep, setStep] = useState(0);
    const [form] = Form.useForm();
    const [options, setOptions] = useState([]);

    useEffect(() => {

        if (username !== null) {

            form.setFieldsValue({
                username: username,
            });

            OnClickNext();

        }

    }, [username]);

    const OnClickNext = () => {

        form.validateFields().then(async (values) => {

            if (getStep === 0) {

                const formdata = new FormData();
                formdata.append("username", values.username);

                setLoader(true);

                fetch("https://otobonus.thecoder365.com/api/public/username", {
                    method: "POST",
                    body: formdata,
                    redirect: "follow"
                })
                    .then((response) => response.json())
                    .then((result) => {

                        if (result.status !== "success") {
                            throw new Error(result.message);
                        }

                        setLoader(false);
                        setStep(getStep + 1)

                    })
                    .catch((error) => {
                        setLoader(false);
                        notification.error({
                            message: "HATA",
                            description: error.message,
                            role: "alert",
                            placement: "top"
                        });
                    });

            }

            if (getStep === 1) {


                const formdata = new FormData();
                formdata.append("username", values.username);

                setLoader(true);

                fetch(`https://otobonus.thecoder365.com/api/public/bonus/${values.bonus_type}`, {
                    method: "POST",
                    body: formdata,
                    redirect: "follow"
                })
                    .then((response) => response.json())
                    .then((result) => {

                        if (result.status !== "success") {
                            throw new Error(result.message);
                        }

                        setBonusToken(result.data.token);
                        setOptions([])
                        result.data.options.map(item => setOptions(options => [...options, {
                            value: item.option,
                            label: item.description,
                            ...item
                        }]));
                        setLoader(false);
                        setStep(getStep + 1)

                    })
                    .catch((error) => {
                        setLoader(false);
                        notification.error({
                            message: "HATA",
                            description: error.message,
                            role: "alert",
                            placement: "top"
                        });
                    });

            }


            if (getStep === 2) {


                const formdata = new FormData();
                formdata.append("token", getBonusToken);
                formdata.append("option", values.bonus_option);

                setLoader(true);

                fetch(`https://otobonus.thecoder365.com/api/public/bonus/${values.bonus_type}/save`, {
                    method: "POST",
                    body: formdata,
                    redirect: "follow"
                })
                    .then((response) => response.json())
                    .then((result) => {

                        if (result.status !== "success") {
                            throw new Error(result.message);
                        }


                        setTimeout(() => {

                            setBonusToken(null);
                            setStep(0);
                            setOptions([]);
                            setLoader(false);
                            form.resetFields()
                            form.setFieldsValue({
                                username: username,
                            });

                            notification.success({
                                message: result.title,
                                description: result.message,
                                role: "alert",
                                placement: "top"
                            });

                        }, 2500);

                        setStep(getStep + 1);




                    })
                    .catch((error) => {
                        setLoader(false);
                        notification.error({
                            message: "HATA",
                            description: error.message,
                            role: "alert",
                            placement: "top"
                        });
                    });

            }


        })
            .catch(() => {
                notification.error({
                    message: "HATA",
                    description: "Lütfen bilgileri eksiksiz giriniz",
                    role: "alert",
                    placement: "top"
                });
            })

    }

    if(getLoader){
        return (
            <Result
                icon={<LoadingOutlined />}
                title="Lütfen Bekleyiniz"
            />
        )
    }


    return (
        <div style={{paddingTop: 10}}>

            <Steps
                direction={"horizontal"}
                type={"default"}
                responsive={false}
                activeStep={getStep}
                size={"small"}
                current={getStep}
                items={[
                    {
                        title: 'Giriş',
                        status: getStep >= 0 ? "finish" : "process"
                    },
                    {
                        title: 'Tür',
                        status: getStep >= 1 ? "finish" : "process"
                    },
                    {
                        title: 'Bonus',
                        status: getStep >= 2 ? "finish" : "process"
                    },
                    {
                        title: 'Sonuç',
                        status: getStep >= 3 ? "finish" : "process"
                    },
                ]}
            />

            <Form
                disabled={getLoader}
                form={form}
                size={"large"}
                style={{marginTop: 35}}
                layout="vertical"
                name="basic"
                onFinish={() => {
                }}
                onFinishFailed={() => {
                }}
                autoComplete="off">

                <Row gutter={[0, 5]}>

                    {
                        (getStep >= 0 && getStep < 3) &&
                        <Col span={24}>
                            <Form.Item
                                label="Kullanıcı Adı"
                                name="username"
                                rules={[{required: true, message: 'Zorunlu Alan!'}]}>
                                <Input disabled={getLoader || getStep > 0} loading={getLoader} prefix={<UserOutlined/>}
                                       placeholder={"Kullanıcı adınızı giriniz..."}/>
                            </Form.Item>
                        </Col>
                    }


                    {
                        (getStep >= 1 && getStep < 3) &&
                        <Col span={24}>
                            <Form.Item
                                label="Bonus Tipi"
                                name="bonus_type"
                                rules={[{required: true, message: 'Zorunlu Alan!'}]}>

                                <Select
                                    disabled={getLoader || getStep > 1}
                                    loading={getLoader}
                                    suffixIcon={<SubnodeOutlined/>}
                                    showSearch
                                    placeholder="Seçim Yapınız"
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={[
                                        {
                                            key: `bnit_${Math.random()}`,
                                            value: 'deposit',
                                            label: 'Yatırım Bonusu',
                                        },
                                        {
                                            key: `bnit_${Math.random()}`,
                                            value: 'loss',
                                            label: 'Kayıp Bonusu',
                                        },
                                        {
                                            key: `bnit_${Math.random()}`,
                                            value: 'shared',
                                            label: 'Paylaşımlı Bonus',
                                        }
                                    ]}
                                />

                            </Form.Item>
                        </Col>
                    }

                    {
                        (getStep >= 2 && getStep < 3) &&
                        <Col span={24}>
                            <Form.Item
                                label="Bonus"
                                name="bonus_option"
                                rules={[{required: true, message: 'Zorunlu Alan!'}]}>

                                <Select
                                    notFoundContent={<>
                                        <Result
                                            status={"info"}
                                            title="Bonus bulunamadı"
                                        /></>}
                                    disabled={getLoader}
                                    optionLabelProp="label"
                                    loading={getLoader}
                                    options={options.map(item => ({
                                        key: `bnit_${Math.random()}`,
                                        value: item.option,
                                        label: item.title,
                                        status: item.status,
                                        disabled: !item.status,
                                        description: item.description
                                    }))}
                                    optionRender={(input, option) => (
                                        <Descriptions bordered style={{
                                            border: !input.data.status ? "1px solid #0000ff1c" : "1px solid red",
                                            borderRadius: 16
                                        }}>
                                            <Descriptions.Item span={12}
                                                               label="Bonus">{input.data.label}</Descriptions.Item>

                                            <Descriptions.Item span={12} label="Açıklama">
                                                <p>
                                                    {input.data.description}
                                                </p>
                                            </Descriptions.Item>
                                            <Descriptions.Item span={12} label="Durum">
                                                <Tag color={!input.data.status ? "red" : "blue"}>
                                                    {!input.data.status ? "GEÇERSİZ" : "GEÇERLİ"}
                                                </Tag>
                                            </Descriptions.Item>
                                        </Descriptions>
                                    )}
                                    suffixIcon={<SubnodeOutlined/>}
                                    placeholder="Seçim Yapınız"/>

                            </Form.Item>
                        </Col>
                    }

                    {
                        getStep === 3 &&
                        <Col span={24}>
                            <Result
                                icon={<CheckCircleOutlined style={{color: 'red'}}/>}
                                title="İşlem Başarılı"
                                subTitle="Talebiniz işleme alındı, Lütfen bu süre zarfında bekleyiniz"
                            />
                        </Col>
                    }

                </Row>

            </Form>

            <div style={{
                marginTop: 10,
                marginBottom: 35,
                display: 'flex',
                gap: '10px',
                justifyContent: "space-between"
            }}>
                {
                    (getStep >= 1 && getStep < 3) ?
                        <Button
                            disabled={getLoader}
                            loading={getLoader}
                            shape={"round"}
                            style={{width: 150}}
                            icon={<ArrowLeftOutlined/>}
                            type="text"
                            onClick={() => {
                                if (getStep === 2) {
                                    setOptions([]);
                                    form.resetFields(["bonus_option"])
                                }
                                if (username == null) {
                                    setStep(getStep - 1)
                                } else {
                                    if (getStep > 1) {
                                        setStep(getStep - 1);
                                    }
                                }
                            }}>
                            Geri
                        </Button> : <div style={{width: 150}}/>
                }


                {
                    getStep < 3 &&
                    <Button
                        disabled={getLoader}
                        loading={getLoader}
                        shape={"round"}
                        style={{width: 150}}
                        icon={<ArrowRightOutlined/>}
                        type="primary"
                        onClick={OnClickNext}>
                        {getStep < 2 && "Devam et"}
                        {getStep === 2 && "Talebi Gönder"}
                    </Button>
                }

                {
                    getStep === 3 &&
                    <>

                    </>
                }

            </div>

        </div>
    )
}


export default BonusRequest;