import './App.css';
import {Affix, App as AntApp, Col, Form, Image, Input, Row, Select, Steps, Tabs} from 'antd';
import BonusRequest from "./pages/BonusRequest";
import BonusHistories from "./pages/BonusHistories";
import {isMobile} from "react-device-detect";
import {useEffect, useState} from "react";
import Config from "./Config";

const onChange = (key) => {
    console.log(key);
};


function App() {


    const [getUsernameFromPath, setUsernameFromPath] = useState(null)

    useEffect(() => {

        window.document.title = Config.title;

        const findUsername = new URLSearchParams(window.location.search)
        setUsernameFromPath(findUsername.get("username"));

    }, []);

    return (
        <div style={{
            position: "relative",
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%'
        }}>

            {
                !isMobile &&
                <div
                    style={{
                        opacity: 0.7,
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                        left: 0,
                        top: 0,
                        zIndex: 1,
                        backgroundColor: '#fff',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        backgroundImage: `url(${Config.bg})`
                    }}/>
            }

            <div style={{
                zIndex: 2,
                display: "flex",
                flexDirection: "column",
                width: isMobile ? "100%" : 700,
                minWidth: isMobile ? "100%" : 700,
                maxWidth: isMobile ? "100%" : 700,
                background: "#fff",
                minHeight: isMobile ? "100%" : "100%",
                boxShadow: "rgb(8 23 71 / 27%) 0px 0 20px",
                backdropFilter: "blur(5px)"
            }}>

                <div style={{
                    overflow: "hidden",
                    position: "relative",
                    width: "100%",
                    backgroundColor: "#fff",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: 'flex-start',
                    marginBottom: 0
                }}>
                    <div style={{
                        position: "relative",
                        zIndex: 9,
                        color: "#fff",
                        width: "100%",
                        padding: "30px 25px",
                        backdropFilter: "blur(3px)"
                    }}>
                        <Image
                            style={{marginTop: 20, marginBottom: 20}}
                            preview={false}
                            width={250}
                            src={Config.logo}
                        />
                        <h1 style={{fontSize: 25, marginTop: 25, marginBottom: 0, color: "#000"}}>
                            Hızlı <span style={{fontWeight: 100}}>OtoBonus</span>
                        </h1>
                        <h6 style={{fontSize: 16, marginTop: 5, fontWeight: 200, marginBottom: 25, color: "#000"}}>
                            Lütfen kullanıcı adınızı doğru girmeyi unutmayın
                        </h6>
                    </div>
                    <div
                        style={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                            left: 0,
                            top: 0,
                            zIndex: 1,
                            background: 'rgb(255 255 255 / 87%)'
                        }}/>
                    <img
                        src="https://www.shutterstock.com/image-illustration/3d-render-blue-yellow-stadium-600nw-2137591647.jpg"
                        style={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                            left: 0,
                            top: 0,
                            zIndex: 0
                        }}
                        alt=""/>
                </div>

                <div style={{width: "calc(100% - 50px)", flex: 1, paddingLeft: 25, paddingRight: 25, paddingTop: 5, margin: "0 auto"}}>
                    <Tabs defaultActiveKey="1" items={[
                        {
                            key: '1',
                            label: 'Bonus Talep',
                            children: <BonusRequest username={getUsernameFromPath} />
                        },
                        {
                            key: '2',
                            label: 'Bonus Sorgula',
                            children: <BonusHistories username={getUsernameFromPath}/>
                        }
                    ]} onChange={onChange}/>
                </div>

                <div style={{
                    overflow: "hidden",
                    position: "relative",
                    width: "100%",
                    backgroundColor: "#fff",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: 'flex-start',
                    height: 50
                }}>
                    <div style={{position: "relative", zIndex: 9, color: "#fff", paddingLeft: 25}}>
                        © 2024 Dev Team | {window.uuid()}
                    </div>
                    <div
                        style={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                            left: 0,
                            top: 0,
                            zIndex: 1,
                            background: Config.color
                        }}/>
                </div>

            </div>

        </div>
    );


}

export default App;
