import React, {useEffect, useState} from "react";
import {App, Button, Result} from "antd";
import AdminLogin from "./AdminLogin";
import {useNavigate} from "react-router-dom";
import {

    LoadingOutlined,
} from '@ant-design/icons';


const Guard = (props) => {

    const navigate = useNavigate();
    const {message, notification, modal} = App.useApp();

    const [getAuthed,setAuthed] = useState(false);
    const [getLoading,setLoading] = useState(true);


    useEffect(() => {

        window.document.title = "Yönetici Paneli";

        logOutAction();

    }, []);



    const logOutAction = (values) => {

        const formdata = new FormData();


        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);
        myHeaders.append("Accept", "application/json");

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formdata,
        };

        setLoading(true);
        fetch("https://otobonus.thecoder365.com/api/admin/check", requestOptions)
            .then((response) => response.json())
            .then((result) => {

                if (result.status !== "success") {
                    throw new Error(result.message);
                }


                setLoading(false);
                setAuthed(true);

            })
            .catch((error) => {
                setLoading(false);
            });

    }


    if(getAuthed){
        return props.children;
    }else{
        if(getLoading){
            return (
                <div style={{
                    width: "100vw",
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                    <Result
                        icon={ <LoadingOutlined />}
                        title="Lütfen Bekleyiniz"
                        subTitle="İşlemler kontrol ediliyor, Kontrol ardınsan sizi yönlendireceğiz."
                    />
                </div>
            )
        }else{
            return <AdminLogin onLogin={() => {
                logOutAction();
            }}/>
        }
    }
};


export default Guard;