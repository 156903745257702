import React from "react";
import Logo from "./assets/RoketbetLogoDark.svg";
import bg from "./assets/robg.jpg";


const Config = {


    logo: Logo,

    bg: bg,

    title: "RoketBET OtoBonus",

    color: "#f5222d",


}

export default Config;