import React, { useState } from "react";
import {Breadcrumb, Button, Card, Table, Space, Row, Col} from "antd";
import {useMatches} from "react-router-dom";
import {PlusOutlined} from '@ant-design/icons';
import Shared from "./categories/Shared";
import Deposit from "./categories/Deposit";
import Loss from "./categories/Loss";


const dataSource = [
    {
        key: '1',
        name: 'Mike',
        age: 32,
        address: '10 Downing Street',
    },
    {
        key: '2',
        name: 'John',
        age: 42,
        address: '10 Downing Street',
    },
];

const columns = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Age',
        dataIndex: 'age',
        key: 'age',
    },
    {
        title: 'Address',
        dataIndex: 'address',
        key: 'address',
    },
];

const ManagementAdmin = () => {

    return (
        <>
           <Row gutter={[24,24]}>
               <Col span={24}>
                   <Deposit/>
               </Col>
               <Col span={24}>
                   <Loss/>
               </Col>
               <Col span={24}>
                   <Shared/>
               </Col>
           </Row>
        </>
    )
}

export default ManagementAdmin;